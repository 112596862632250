import "flipclock";
import "flipclock/dist/flipclock.css";

$(document).ready(function () {
  function setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  var initialTotal = parseInt(getCookie("total"));
  if (isNaN(initialTotal)) {
    initialTotal = parseInt(212417);
  }
  initialTotal += Math.floor(Math.random() * 100);

  var clock = $(".total-hits").FlipClock(initialTotal, {
    clockFace: "Counter",
  });

  function incrementClock() {
    clock.increment();
    initialTotal += 1;

    setCookie("total", initialTotal);

    var randomTimer = Math.floor(Math.random() * 5) * 1000;
    setTimeout(incrementClock, randomTimer);
  }
  incrementClock();
});
