// Contact List
$(document).ready(function () {
  $(".contact-title").on("click", function () {
    if ($(".contact-links").hasClass("clicked")) {
      $(".contact-links").addClass("clicked-out");
      var total = $(".contact-links li").length;
      $(".contact-links li").each(function (i, e) {
        var animationDelay = String(--total * 200);
        animationDelay += "ms";
        $(e).css("animation-delay", animationDelay);
      });
      $(".contact-links").removeClass("clicked");
    } else {
      $(".contact-links").removeClass("clicked-out");
      $(".contact-links li").each(function (i, e) {
        var animationDelay = String(++i * 200);
        animationDelay += "ms";
        $(e).css("animation-delay", animationDelay);
      });
      $(".contact-links").addClass("clicked");
    }
  });

  $("a").on("click", function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top,
        },
        800,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        }
      );
    } // End if
  });
});
